import React from 'react'
import { AboutInfo } from '../components/AboutInfo/AboutInfo'

export const About = () => {
  return (
    <div>
      <AboutInfo />
    </div>
  )
}
